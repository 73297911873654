@use "src/theme/variables.scss" as v;
@import "~@angular/material/theming";
@import "./rina-colors.scss";

$dark-theme-primary: mat-palette(
  $rina-grayweb,
  50,
  100,
  500,
  900
); // most widely used across all screen and components
$dark-theme-accent: mat-palette(
  $rina-blue,
  50,
  100,
  500,
  900
); // floating action color and interactive element
$dark-theme-foreground: mat-palette(
  $rina-lightblue,
  50,
  100,
  500,
  900
); // for text and icons
$dark-theme-warn: mat-palette($rina-warn, 50, 100, 500, 900);
$dark-theme: mat-dark-theme(
  $dark-theme-primary,
  $dark-theme-accent,
  $dark-theme-foreground
);

@function color-get($map, $key, $opacity) {
  // input palette, color number, opacity  --> output rgba
  $value: map-get($map, $key);
  @return rgba($value, $opacity);
}

.light-theme {
  /**************************************************
                      RINA
  **************************************************/
  .drawer {
    background-image: linear-gradient(
      color-get($dark-theme-accent, 500, 1),
      color-get($dark-theme-foreground, 500, 1)
    );
  }

  .sidenav {
    background-image: linear-gradient(
      color-get($dark-theme-accent, 500, 1),
      color-get($dark-theme-foreground, 500, 1)
    );
  }

  %page-toolbar {
    background: mat-color($rina-lightblue, 500);
    color: white;
    height: 55px;

    mat-nav-list mat-list-item,
    mat-list mat-list-item {
      color: white;
      &.active {
        color: mat-color($rina-darkblue, 500);
      }
    }
  }

  %rina-text {
    color: mat-color($rina-darkblue, 500, 0.7);

    &:hover {
      color: mat-color($rina-darkblue, 500, 1);
    }
  }

  .mat-tooltip {
    background: mat-color($rina-gray, 500, 0.9);
  }

  .mat-drawer-container {
    color: mat-color($rina-darkblue, 500, 0.7);
    background-color: mat-color($rina-grayweb, 500);
  }

  .mat-tab-label.mat-tab-label-active {
    color: color-get($rina-darkblue, 500, 1);
  }

  cube-sidemenu {
    $dark-theme-primary: mat-palette($rina-lightblue, 500, 100, 800);
    $dark-theme-accent: mat-palette($rina-darkblue, 500, 100, 800);
    $dark-theme-warn: mat-palette($rina-warn);
    $dark-theme: mat-dark-theme(
      $dark-theme-primary,
      $dark-theme-accent,
      $dark-theme-warn
    );
    @include angular-material-theme($dark-theme);
    @include mat-list-theme($dark-theme);
  }

  cube-toolbar {
    .mat-toolbar {
      background: mat-color($rina-grayweb, 500);
      color: mat-color($rina-darkblue, 500);
    }
  }

  cube-profile-tab {
    .profile-select-avatar {
      border: solid 1px rgb(220, 223, 223);
    }
  }

  .mat-toolbar.page-toolbar {
    @extend %page-toolbar;
  }

  mat-form-field {
    &.mat-form-field-appearance-outline {
      .mat-form-field-outline-thick {
        color: mat-color($rina-lightblue, 500);
      }
    }
  }

  cube-details-tab {
    .details-container {
      background: linear-gradient(
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        url("/../assets/map/wind/blades_landscape.png") repeat-x fixed 10px
          bottom;
    }
  }

  /**************************************************
                        Focaloid
  **************************************************/
  cube-custom-no-rows-overlay {
    .no-rows-message {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  cube-debug-panel {
    #debugPanel .ngx-json-viewer .segment .segment-main .segment-key {
      color: black;
    }
  }

  cube-signature-dialog {
    .upload-signature,
    .draw-signature {
      border: solid 1px rgb(220, 223, 223);
    }
  }

  cube-upload-defect-files-dialog,
  cube-upload-file-dialog {
    .list-files {
      color: mat-color($rina-darkblue, 500, 0.7);
    }
  }

  /* Input template related styles */
  .input-template-container {
    // AG Grid
    .ag-theme-alpine {
      .ag-header-cell,
      .ag-header-group-cell {
        background-color: #e9e8e8;
      }

      .ag-ltr .ag-cell {
        background-color: #f8f8f8;
      }
    }

    .cell-pass {
      background-color: rgb(206, 204, 204);
    }

    .defect-header-input-color,
    .defect-header-input-color
      .mat-form-field-appearance-fill
      .mat-form-field-flex {
      background-color: rgb(221 235 247);
    }

    .file-upload-sub-header {
      .section-label {
        background-color: #ffd5bd;
      }
    }

    .group-container
      .left
      .mat-form-field-appearance-fill
      .mat-form-field-flex {
      background-color: #dddda8;
    }

    .group-container .left.light-orange-background {
      background-color: #dddda8;
    }

    .light-green
      .mat-form-field.mat-form-field-appearance-fill
      .mat-form-field-flex {
      background-color: #ddead1;
    }

    .light-green,
    .light-green .mat-form-field,
    .light-green .section-label {
      background-color: #ddead1;
    }

    .light-grey {
      background-color: rgb(206, 204, 204);
    }

    .light-grey-background {
      background-color: rgb(206, 204, 204);
    }

    .light-orange .mat-form-field {
      background-color: #ffd5bd;
    }

    .light-orange
      .mat-form-field.mat-form-field-appearance-fill
      .mat-form-field-flex {
      background-color: #ffd5bd;
    }

    .light-orange-background {
      background-color: #dddda8;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: #f8f8f8;
    }

    .unique-code-sec {
      background-color: rgb(156 194 230);
    }

    .upload-btn {
      color: #000000;
    }

    /* Angular Material Component Styles */
    // Form Field
    .mat-form-field-appearance-fill.mat-form-field-disabled
      .mat-form-field-underline::before {
      background-color: rgba(0, 0, 0, 0.42);
    }
  }

  /* PrimeNG Component Styles */
  // Calendar
  .p-calendar {
    .p-inputtext {
      background-color: transparent !important;
      border-bottom-color: rgba(255, 255, 255, 0.5) !important;
      color: rgba(19, 41, 75, 0.7) !important;

      &:focus,
      &:hover {
        background-color: transparent !important;
        border-bottom-color: rgba(0, 0, 0, 0.42) !important;
        color: rgba(19, 41, 75, 0.7) !important;
      }
    }

    .p-datepicker {
      background-color: rgb(255, 255, 255);
      color: rgba(0, 0, 0, 0.54);
    }

    .p-datepicker .p-timepicker button {
      color: rgba(0, 0, 0, 0.54);

      &:focus,
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        border-color: transparent;
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }

  // Float Label
  .p-float-label label {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  // Paginator
  .p-paginator {
    color: rgba(0, 0, 0, 0.87);

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
      color: rgba(0, 0, 0, 0.6);
    }

    .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
      background: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.6);
    }

    .p-paginator-current {
      color: rgba(0, 0, 0, 0.6);
    }

    .p-paginator-pages .p-paginator-page {
      color: rgba(0, 0, 0, 0.6);

      &:not(.p-highlight):hover {
        background: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .p-paginator-element:focus {
      background: rgba(0, 0, 0, 0.12);
    }
  }

  // Sidebar
  .p-sidebar {
    background: #ededed !important;
    color: rgba(0, 0, 0, 0.87) !important;

    .p-sidebar-close {
      background: transparent !important;
      background-color: rgba(0, 0, 0, 0) !important;
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
}
