@import "~@angular/material/theming";

/* PrimeNG Component Styles */
// Function to get colors from palette
@function color-get($palette, $key, $opacity) {
  $value: map-get($palette, $key);

  @return rgba($value, $opacity);
}

@mixin primeng-theme($theme) {
  // Extract the color configuration from the theme that has been passed.
  $config: mat-get-color-config($theme);
  // Extract the palettes you need from the theme definition.
  $primary-palette: map-get($config, primary);
  $accent-palette: map-get($config, accent);
  $warn-palette: map-get($config, warn);
  // Extract colors
  $primary: color-get($primary-palette, 500, 1);
  $accent: color-get($accent-palette, 500, 1);
  $warn: color-get($warn-palette, 500, 1);

  // Button
  .p-button {
    color: #ffffff;
    background: $primary;

    &:enabled {
      &:hover {
        background: rgba($primary, 0.92);
        color: #ffffff;
      }

      &:active {
        background: rgba($primary, 0.68);
        color: #ffffff;
      }

      &:focus {
        background: rgba($primary, 0.76);
      }
    }

    &.p-button-outlined {
      background-color: transparent;
      color: $primary;

      &:enabled {
        &:hover {
          background: rgba($primary, 0.04);
          color: $primary;
        }

        &:active {
          background: rgba($primary, 0.16);
          color: $primary;
        }
      }
    }

    &.p-button-text {
      background-color: transparent;
      color: $primary;

      &:enabled {
        &:hover {
          background: rgba($primary, 0.04);
          color: $primary;
        }

        &:active {
          background: rgba($primary, 0.16);
          color: $primary;
        }
      }
    }

    .p-badge {
      color: $primary;
      background-color: #ffffff;
    }

    &.p-button-secondary {
      color: #ffffff;
      background: $accent;

      &:enabled {
        &:hover {
          background: rgba($accent, 0.92);
          color: #ffffff;
        }

        &:active {
          background: rgba($accent, 0.68);
          color: #ffffff;
        }

        &:focus {
          background: rgba($accent, 0.76);
        }
      }

      &.p-button-outlined {
        background-color: transparent;
        color: $accent;

        &:enabled {
          &:hover {
            background: rgba($accent, 0.04);
            color: $accent;
          }

          &:active {
            background: rgba($accent, 0.16);
            color: $accent;
          }
        }
      }

      &.p-button-text {
        background-color: transparent;
        color: $accent;

        &:enabled {
          &:hover {
            background: rgba($accent, 0.04);
            color: $accent;
          }

          &:active {
            background: rgba($accent, 0.16);
            color: $accent;
          }
        }
      }

      &.p-button-text:enabled:focus,
      &.p-button-outlined:enabled:focus {
        background: rgba($accent, 0.12);
      }

      &.p-button-text:enabled:active,
      &.p-button-outlined:enabled:active {
        background: rgba($accent, 0.16);
      }

      &.p-button-text .p-ink,
      &.p-button-outlined .p-ink {
        background-color: rgba($accent, 0.16);
      }
    }

    &.p-button-danger {
      color: #ffffff;
      background: $warn;

      &:enabled {
        &:focus {
          background: rgba($warn, 0.76);
        }

        &:hover {
          background: rgba($warn, 0.92);
          color: #ffffff;
        }

        &:active {
          background: rgba($warn, 0.68);
          color: #ffffff;
        }
      }

      &.p-button-text:enabled:focus,
      &.p-button-outlined:enabled:focus {
        background: rgba($warn, 0.12);
      }

      &.p-button-text:enabled:active,
      &.p-button-outlined:enabled:active {
        background: rgba($warn, 0.16);
      }

      &.p-button-text .p-ink,
      &.p-button-outlined .p-ink {
        background-color: rgba($warn, 0.16);
      }

      &.p-button-outlined {
        background-color: transparent;
        color: $warn;

        &:enabled {
          &:hover {
            background: rgba($warn, 0.04);
            color: $warn;
          }

          &:active {
            background: rgba($warn, 0.16);
            color: $warn;
          }
        }
      }

      &.p-button-text {
        background-color: transparent;
        color: $warn;

        &:enabled {
          &:hover {
            background: rgba($warn, 0.04);
            color: $warn;
          }

          &:active {
            background: rgba($warn, 0.16);
            color: $warn;
          }
        }
      }
    }

    &.p-button-text:enabled:focus,
    &.p-button-outlined:enabled:focus {
      background: rgba($primary, 0.12);
    }

    &.p-button-text:enabled:active,
    &.p-button-outlined:enabled:active {
      background: rgba($primary, 0.16);
    }

    &.p-button-text .p-ink,
    &.p-button-outlined .p-ink {
      background-color: rgba($primary, 0.16);
    }
  }

  // Paginator
  .p-paginator {
    background: transparent;
    // color: rgba(0, 0, 0, 0.87);  // Updated in other theme files
    border: none;

    /* .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
      color: rgba(0, 0, 0, 0.6);
    }

    .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
      background: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.6);
    }

    .p-paginator-current {
      color: rgba(0, 0, 0, 0.6);
    } // Updated in other theme files */

    .p-paginator-pages .p-paginator-page {
      color: rgba(0, 0, 0, 0.6);

      &.p-highlight {
        background: rgba($primary, 0.12);
        border-color: rgba($primary, 0.12);
        color: $primary;
      }

      /* &:not(.p-highlight):hover {
        background: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.6);
      } // Updated in other theme files */
    }

    .p-paginator-element:focus {
      // background: rgba(0, 0, 0, 0.12); // Updated in other theme files

      &.p-highlight {
        background: rgba($primary, 0.24);
      }
    }
  }

  // Progress bar
  .p-progressbar {
    background: rgba($primary, 0.32);

    .p-progressbar-value {
      background: $primary;
    }

    .p-progressbar-label {
      color: #ffffff;
    }
  }

  // SpeedDial
  .p-speeddial-action {
    background: $accent;
    color: #fff;

    &:hover {
      background: rgba($accent, 0.92);
      color: #fff;
    }
  }
}
