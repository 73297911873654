/* You can add global styles to this file, and also import other style files */
@use "src/theme/variables" as v;
@import "~@angular/material/theming";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~font-awesome";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.col-span-2 {
  grid-column-end: span 2;

  @media #{v.$lt-sm} {
    grid-column-end: unset;
  }
}

.dialog-form {
  display: grid;
  gap: 2px 10px;
  grid-auto-flow: row dense;
  grid-template-columns: 1fr;

  .action-buttons {
    display: flex;
    gap: 15px;
    grid-column: 1 / -1;
    justify-self: end;
  }
}

.filter-card {
  form {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    @media #{v.$xs} {
      align-items: stretch;
      flex-direction: column;
      width: 100%;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-flat-button {
    font-weight: 500;
    padding-block: 5px;
  }
}

mat-dialog-container.mat-dialog-container {
  overflow: unset;
  padding: 0;
  position: relative;

  .close-icon {
    height: 30px;
    line-height: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;

    .mat-icon {
      font-size: 20px;
      height: 20px;
      line-height: 20px;
      width: 20px;
    }
  }

  $padding-block: 25px;
  $padding-inline: min(5vw, 35px);

  .mat-dialog-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0.75rem;
    padding-block: $padding-block 0;
    padding-inline: $padding-inline;
  }

  .mat-dialog-content {
    margin: 0;
    max-height: 75vh;
    padding-block: 0.5rem $padding-block;
    padding-inline: $padding-inline;
  }
}

.no-data-card {
  text-align: center;
}

.overflow-ellipsis {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;
  word-break: break-all;
}

.page-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: calc(100% - 55px);
  overflow: auto;
  padding-block: 30px;
  padding-inline: 15vw;
}

.rotate-icon {
  animation: rotation 1s infinite linear;
}

.speed-dial.p-speeddial {
  bottom: 25px;
  position: fixed;
  right: 35px;

  &.p-disabled {
    .p-speeddial-button {
      pointer-events: auto;
    }

    .p-speeddial-item {
      cursor: not-allowed !important;
      pointer-events: auto;
    }
  }

  .p-speeddial-button.p-button.p-button-icon-only {
    height: 3rem;
    width: 3rem;
  }
}

table {
  word-break: break-word;
}

.table-container {
  @include mat-elevation(8);
  border-radius: 4px;
  max-width: 100%;
  position: relative;

  > .table-loader {
    align-items: center;
    background: rgba(0, 0, 0, 0.25);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999;

    .loader-container {
      background: none;
    }
  }

  > .table {
    overflow: auto;

    table.mat-table {
      width: 100%;

      tr.mat-row {
        height: 55px;
      }

      th.mat-header-cell {
        border-color: #4f899b;
        border-bottom-width: 1px;
        color: #4f899b;
        font-size: 17px;
        font-weight: 400;
      }

      td.no-data {
        text-align: center;
      }

      th.mat-header-cell:first-of-type,
      td.mat-cell:first-of-type {
        padding-right: 5px;
      }

      th.mat-header-cell:not(:first-of-type):not(:last-of-type),
      td.mat-cell:not(:first-of-type):not(:last-of-type) {
        padding-inline: 5px;
      }

      th.mat-header-cell:last-of-type,
      td.mat-cell:last-of-type {
        padding-left: 5px;
      }
    }
  }
}

/* Input template related styles */
.input-template-container {
  height: 100%;
  overflow: auto;

  .action-buttons-container {
    align-items: center;
    display: flex;
    gap: 20px;
    height: 40px;
    justify-content: end;
    margin-top: 20px;
    padding-inline: 20px;

    button {
      font-weight: 700;
    }
  }

  .ag-cell-edit {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }

  // AG Grid
  .ag-theme-alpine {
    .ag-center-cols-clipper,
    .ag-center-cols-container {
      min-height: 120px !important;
    }

    .ag-ltr .ag-cell,
    .ag-header-cell,
    .ag-header-group-cell {
      border-right: 1px solid #9e9c9c !important;
    }

    .ag-header-cell-label,
    .ag-header-group-cell-label {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ag-header-row {
      overflow: visible !important;
    }
  }

  .altro-width .mat-form-field {
    width: 80px;
  }

  .attention-class {
    &.alta {
      $bgColor: #f88f86;
      $color: #270404;

      background-color: $bgColor !important;
      color: $color !important;

      .mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
        background-color: $bgColor !important;
      }

      .mat-select-value {
        color: $color !important;
      }
    }

    &.bassa {
      $bgColor: #a8d285;
      $color: #2e2828;

      background-color: $bgColor !important;
      color: $color !important;

      .mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
        background-color: $bgColor !important;
      }

      .mat-select-value {
        color: $color !important;
      }
    }

    &.media {
      $bgColor: #ffe697;
      $color: #2b1f1f;

      background-color: $bgColor !important;
      color: $color !important;

      .mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
        background-color: $bgColor !important;
      }

      .mat-select-value {
        color: $color !important;
      }
    }

    &.medio-alta {
      $bgColor: #fdbd06;
      $color: #2b1f1f;

      background-color: $bgColor !important;
      color: $color !important;

      .mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
        background-color: $bgColor !important;
      }

      .mat-select-value {
        color: $color !important;
      }
    }

    &.medio-bassa {
      $bgColor: #cadfb7;
      $color: #332c2c;

      background-color: $bgColor !important;
      color: $color !important;

      .mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
        background-color: $bgColor !important;
      }

      .mat-select-value {
        color: $color !important;
      }
    }
  }

  .cell-wrapping {
    align-items: center;
    display: flex;
    justify-content: center;
    line-height: 1.25;

    .ag-cell-wrapper {
      height: auto;
    }
  }

  .content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .custom-select-field {
    .mat-select-arrow-wrapper {
      transform: translateY(0%);
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .defect-rina-logo {
    font-size: 74px;
    height: 131px;
    margin: auto;
    padding: 5px;
    text-align: center;

    img {
      height: 100%;
    }
  }

  .disable-row {
    background-color: rgb(61 61 61 / 30%) !important;
  }

  .display-flex {
    display: flex;

    &-wrap {
      display: flex;
      flex-wrap: wrap;
    }

    &-column {
      display: flex;
      flex-direction: column;
    }
  }

  .file-upload-header {
    .section-label {
      background-color: unset;
      border: 0px none !important;
      text-align: unset;
    }
  }

  .file-upload-sub-header {
    .section-label {
      margin-top: 0px !important;
    }
  }

  .flex-row-to-column-mobile {
    @media only screen and (max-width: 481px) {
      flex-direction: column;
    }
  }

  .gap {
    gap: 35px;
    padding-top: 15px;
  }

  .grid-gap {
    gap: 5px 10px;

    &-extra {
      gap: 25px 20px;
    }
  }

  .header-no-child {
    border-top: 0 !important;
    overflow: visible;

    .ag-header-cell-comp-wrapper {
      position: absolute;
      right: 0;
      top: -12px;
    }
  }

  .height-set {
    height: 84px;
  }

  .input .mat-form-field {
    padding-left: 10px;
    padding-right: 10px;
  }

  .input-field-aligment {
    formly-wrapper-mat-form-field .mat-form-field {
      .mat-form-field-infix {
        width: auto;
      }
    }
  }

  .input-line .mat-form-field-underline {
    display: block;
  }

  .input-text-align {
    padding-left: 4px;
    padding-top: 25px;
  }

  .input-width .mat-form-field {
    margin-top: -15px;
  }

  .main-section {
    display: flex;

    .sub-sec {
      width: 100%;
    }
  }

  .mat-form-field {
    text-align: unset;

    &-disabled .mat-form-field-flex {
      cursor: auto;

      * {
        cursor: auto;
      }
    }

    &.mat-form-field-appearance-outline {
      .mat-form-field-outline-end,
      .mat-form-field-outline-start {
        border-width: 1px !important;
        color: #e0e0e0;
      }
    }

    &.mat-form-field-appearance-fill .mat-form-field-flex {
      padding: 0.1em 0.6em 0 0.6em;
    }
  }

  .mat-form-field-type-mat-input,
  .mat-form-field-type-mat-select {
    &.mat-form-field-disabled .mat-form-field-flex {
      cursor: not-allowed;

      * {
        cursor: not-allowed;
      }
    }
  }

  .mat-radio-disabled,
  .mat-radio-disabled .mat-radio-label {
    cursor: not-allowed;
  }

  .mat-form-field-disabled .mat-checkbox-disabled,
  .mat-checkbox-disabled *,
  .mat-checkbox-disabled + .checkbox-item-name,
  .mat-checkbox-disabled + .checkbox-item-des {
    cursor: not-allowed !important;
  }

  .mat-button-disabled,
  .mat-button-disabled * {
    cursor: not-allowed !important;
  }

  .notes-font-size {
    font-size: 12px;
  }

  .padding-section {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0px;
    padding-right: 10px;
  }

  .page-heading {
    align-items: center;
    border: 1px solid #6f6f6f;
    display: flex;
    margin: auto 0px;
    padding-inline: 10px;

    .page-heading-text {
      font-size: 30px;
      font-weight: 500;
      line-height: initial;
      margin: auto 0px;
      padding-block: 15px;
      text-align: left;
      width: 100%;

      .prefix-block {
        display: inline-flex;
        width: 225px;
      }
    }

    .page-heading-image {
      align-items: center;
      border-left: 1px solid #6f6f6f;
      display: flex;
      justify-content: center;
      padding-block: 15px;
      width: 25%;

      img {
        max-height: 75px;
        vertical-align: middle;
      }
    }
  }

  .page-heading-image-only {
    align-items: center;
    border: 1px solid #6f6f6f;
    display: flex;
    margin: auto 0px;
    padding: 10px;
  }

  .radio-item:nth-child(1) {
    width: 100%;
  }

  .radio-margin {
    .radio-item {
      margin-top: 20px;
      width: 100%;

      &:nth-child(1) {
        margin-top: 0px;
      }
    }
  }

  .radio-sec {
    .mat-form-field-underline {
      display: none;
    }

    .input-line .mat-form-field-underline {
      display: block;
    }
  }

  .radio-sec-custom-aligment {
    formly-wrapper-mat-form-field .mat-form-field {
      width: auto;

      .mat-form-field-infix {
        width: auto;
      }

      .radio-item {
        width: fit-content;
      }
    }
  }

  .remove-underline {
    .mat-form-field-underline {
      display: none;
    }

    .input-line .mat-form-field-underline {
      display: block;
    }

    formly-ag-grid-cell {
      width: 100%;
    }
  }

  .repeat-cell {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .repeat-header {
    width: calc(100% - 40px);
  }

  .sub-heading {
    color: red;
    font-size: 25px;
    padding: 10px;
    text-align: center;
  }

  .sub-title {
    font-size: 25px;
    padding: 10px;
    text-align: center;
  }

  .text-box {
    display: flex;
    flex-direction: column;
  }

  .upload-section {
    height: 30vw;
    overflow: hidden;
  }

  .upload-section-large {
    height: inherit;

    mat-form-field,
    .mat-form-field-wrapper,
    .mat-form-field-flex,
    .mat-form-field-infix,
    formly-field-file {
      height: 100%;
    }
  }

  .upload-section-medium {
    height: 150px;
    overflow: scroll;
  }

  .upload-section-small {
    height: 80px;
    overflow: scroll;
  }

  .width-100-mobile {
    @media only screen and (max-width: 481px) {
      width: 100%;
    }
  }

  /* Utility classes */
  .align-items-center {
    align-items: center;
  }

  .border-all {
    border: 1px solid #cccccc;
  }

  .border-bottom {
    border-bottom: 1px solid #cccccc;
  }

  .border-left {
    border-left: 1px solid #cccccc;
  }

  .border-right {
    border-right: 1px solid #cccccc;
  }

  .border-top {
    border-top: 1px solid #cccccc;
  }

  .border-top-none {
    border-top: none;
  }

  .cursor-auto,
  .cursor-auto * {
    cursor: auto !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .display-inline-flex {
    display: inline-flex;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-2 {
    flex: 2;
  }

  .flex-3 {
    flex: 3;
  }

  .flex-4 {
    flex: 4;
  }

  .flex-5 {
    flex: 5;
  }

  .flex-6 {
    flex: 6;
  }

  .float-right {
    float: right;
  }

  .font-color-black {
    color: #000;
  }

  .font-color-red {
    color: red;
  }

  .font-size-14 {
    font-size: 14px;
  }

  .font-size-18 {
    font-size: 18px;
  }

  .font-weight-bolder {
    font-weight: bolder;
  }

  .font-weight-normal {
    font-weight: normal;
  }

  .font-weight-600 {
    font-weight: 600;
  }

  .height-auto {
    height: auto;
  }

  hr {
    border-color: #faeb94;
  }

  .justify-content-center {
    justify-content: center;
  }

  .margin-auto {
    margin: auto;
  }

  .margin-bottom-30 {
    margin-bottom: 30px;
  }

  .margin-top-15 {
    margin-top: 15px;
  }

  .margin-top-20 {
    margin-top: 20px;
  }

  .margin-top-30 {
    margin-top: 30px;
  }

  .padding-10 {
    padding: 10px;
  }

  .padding-bottom-10 {
    padding-bottom: 10px;
  }

  .padding-inline-10 {
    padding-inline: 10px;
  }

  .padding-left-5 {
    padding-left: 5px;
  }

  .padding-left-8 {
    padding-left: 8px;
  }

  .padding-left-10 {
    padding-left: 10px;
  }

  .padding-left-35 {
    padding-left: 35px;
  }

  .padding-left-40 {
    padding-left: 40px;
  }

  .padding-right-10 {
    padding-right: 10px;
  }

  .padding-top-2 {
    padding-top: 2px;
  }

  .padding-top-10 {
    padding-top: 10px;
  }

  .padding-top-30 {
    padding-top: 30px;
  }

  .required-marker {
    color: #ff2c00;
  }

  .text-align-center {
    text-align: center;
  }

  .text-align-left {
    text-align: left;
  }

  .white-space-no-wrap {
    white-space: nowrap;
  }

  .width-10 {
    width: 10%;
  }

  .width-20 {
    width: 20%;
  }

  .width-35 {
    width: 35%;
  }

  .width-50 {
    width: 50%;
  }

  .width-100 {
    width: 100%;
  }
}

/* Angular Material Component Styles */
// Form Field
.mat-form-field-disabled .mat-form-field-flex {
  cursor: not-allowed;

  * {
    cursor: not-allowed;
  }
}

.mat-form-field-label .mat-form-field-required-marker {
  color: #ff2c00 !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled
  .mat-form-field-underline::before {
  background-color: unset;
}

// Paginator
mat-paginator
  .mat-form-field.mat-paginator-page-size-select
  > .mat-form-field-wrapper {
  padding-bottom: 6px;

  .mat-form-field-infix {
    padding: 0.75em 0;
  }
}

// Tabs
.mat-tab-body-wrapper {
  margin-block-start: 25px;
}

.mat-tab-label {
  font-size: 16px;
  font-weight: 500;

  &.mat-tab-label-active {
    font-weight: 600;
  }
}

/* PrimeNG Component Styles */
// Speed Dial
p-speeddial .p-speeddial {
  z-index: 9999;
}

.p-speeddial-mask {
  z-index: 999;
}
