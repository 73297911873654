@import "~@angular/material/theming";

@mixin cube-unauthorized-theme($theme) {
    $primary: map-get($theme, accent);
    $warn: map-get($theme, warn);

    cube-unauthorized {
        color: mat-color($warn);
        background-color: #e0e0e1;
    }
}
