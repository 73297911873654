
@import "~@angular/material/theming";
@mixin cube-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .sidenav{
        background: mat-color($primary);
        width: 250px;
    }

    .drawer{
        background: mat-color($primary);
        width: 80px;
    }

    /*
    .mat-toolbar h1.logo{
        font-size: 26px;
        font-family: Roboto;
        color: mat-color($warn); 
        margin-left: 15px;
    }*/

}

@mixin cube-card-theme($theme) {
    .pull-right{
        flex: 1 1 auto;
    }
}

